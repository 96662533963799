<template>
    <div>
        <div class="table_box" v-show="!detailsIsShow">
            <el-row align="middle" type="flex"
                    style="background: white;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 20px">
                <el-col :span="5">
                    <div class="keyword">
                        <span>{{$t("m.customer.guanjiangci")}}：</span>
                        <el-input v-model="keywords" :placeholder="$t('m.customer.qingshuruguanjianci')" style="height: 40px;"></el-input>
                    </div>
                </el-col>
<!--                <el-col :span="5">-->
<!--                    <div class="keyword">-->
<!--                        <span>{{$t("m.customer.qudao")}}：</span>-->
<!--                        <el-select :placeholder="$t('m.customer.qingxuanze')" v-model="selectPosition">-->
<!--                            <el-option-->
<!--                                    v-for="item in channels"-->
<!--                                    :key="item.id"-->
<!--                                    :label="item.channel"-->
<!--                                    :value="item.id">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
<!--                    </div>-->
<!--                </el-col>-->
                <el-col :span="10" :offset="1">
                    <div class="keyword">
                        <span>{{$t("m.customer.zhuceshijian")}}：</span>
                        <el-date-picker
                                v-model="time"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                align="right"
                                unlink-panels
                                :range-separator="$t('m.pubilc.zhi')"
                                :start-placeholder="$t('m.customer.kaishishijian')"
                                :end-placeholder="$t('m.customer.jieshushijiang')">
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :span="2" align="end">
                    <el-button type="primary" size="small" @click="search_keywords">{{$t("m.pubilc.sousuo")}}</el-button>
                </el-col>
            </el-row>
            <el-row class="eltable">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="photoUrl" :label="$t('m.customer.kehu')" align="center">
                        <template slot-scope="scope">
                            <div style="display: flex;align-items: center">
                                <img v-if="scope.row.photoUrl" :src="imageUrl(scope.row.photoUrl)" class="goods-image-box" />
                                <div class="goods-image-box" v-else>
                                    <img src="../../../assets/edit-imgs/making/default-icon.png" />
                                </div>
                                <span>{{scope.row.name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="account" :label="$t('m.customer.zhanghao')">
                        <template slot-scope="scope">
                            <div>{{scope.row.email}}</div>
                            <div>{{scope.row.account}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="profession" :label="$t('m.customer.zhiye')">
                    </el-table-column>
                    <el-table-column prop="merchantName" :label="$t('m.customer.qiyeyuzuzhi')">
                    </el-table-column>
                    <el-table-column prop="phone" :label="$t('m.customer.shoujuhao')">
                    </el-table-column>
                    <el-table-column prop="email" :label="$t('m.customer.youxian')">
                    </el-table-column>
                    <el-table-column prop="createTime" :label="$t('m.customer.zhuceshijian')">
                    </el-table-column>
                    <el-table-column prop="merchantCount" :label="$t('m.customer.jiruqiye')" align="center">
                    </el-table-column>
                    <el-table-column prop="miniProCount" :label="$t('m.customer.xiezuo')" align="center">
                    </el-table-column>
                    <el-table-column :label="$t('m.pubilc.caozuo')" width="80" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="check(scope.row)">{{$t("m.pubilc.chakan")}}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
            <el-row type="flex" style="justify-content: flex-end;margin: 15px 0 0;">
                <el-pagination class="pagination" background layout="total,prev, pager, next" :total="total"
                               @current-change='current_change' style="margin-top: 10px">
                </el-pagination>
            </el-row>
        </div>
        <listDetails v-if="detailsIsShow" :currentId="currentId" @handleReturn="handleReturn"></listDetails>
    </div>
</template>

<script>
    import {
        getAccountList
    } from 'request/api/client_list.js'
    import {getImageUrl} from "../../../utils/ImageUtils"
    import listDetails from "./details";


    export default {
        components:{
            listDetails
        },
        data() {
            return {
                selectPosition: null,
                pageNum: 1,
                pageSize: 10,
                tableData: [],
                total: 0,
                keywords: '',
                channels: [],
                time: [],
                detailsIsShow:false,
                currentId:null,
            }
        },
        created() {
            // this.selectChannelAll()
            this.queryPage()
        },
        methods: {
            imageUrl(url){
                return getImageUrl(url)
            },
            check(e) {
                this.currentId = e.id
                this.detailsIsShow = true
            },
            handleReturn(){
                this.detailsIsShow = false
                this.currentId = null
            },
            queryPage(pagenum = 1) {
                let params={
                    pageNum: pagenum,
                    pageSize: this.pageSize,
                }
                if(this.keywords.length>0){
                    params.keyword = this.keywords
                }
                if(this.time&&this.time.length>0){
                    params.startRegisterTime = this.time[0]
                    params.endRegisterTime = this.time[1]
                }
                getAccountList(params).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.list
                        this.total = res.data.total
                    }
                })
            },
            selectChannelAll() {
                findAllChannel({}).then(res => {
                    console.log(res)
                    if (res.code == 200) {
                        this.channels.push({
                            id: -1,
                            channel: this.$t("m.customer.all")
                        })
                        for (let i = 0; i < res.data.length; i++) {
                            this.channels.push({
                                id: res.data[i].id,
                                channel: res.data[i].channel
                            })
                        }
                    } else {
                        this.channels.push({
                            id: -1,
                            channel: this.$t("m.customer.all")
                        })
                    }
                })
            },
            handleSelectionChange(e) {
                console.log(e);
            },
            // 分页改变事件
            current_change(e) {
                this.pageNum = e
                this.queryPage(e)
            },
            // 搜索关键词事件
            search_keywords() {
                this.queryPage()
            }
        },

    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    .table_box {
        padding: 15px;
        margin: auto;
        background: #fff;
    }

    .search_box {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }

    .keyword {
        height: 50px;
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .keyword span {
        width: 80px;
        text-align: center;
    }

    .goods-image-box {
        width: 40px;
        height: 40px;
        align-content: center;
        border-radius: 40px;
        margin-left: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 5px;
        background: #eee;
        flex-shrink: 0;
        position: relative;
        img{
            width: 26px;
            height: 23px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -11.5px;
            margin-left: -13px;
        }
    }
</style>
